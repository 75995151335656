export const FLAGS = {
  /** Golden Gurls Flags */
  almostGone: 'ggAlmostGone',
  goldAchOnKiosk: 'ggGoldAchOnKiosk',
  janeGoldUserSegmentation: 'fepGoldUserSegmentation',
  kioskMyHighMenu: 'ggKioskMyHighMenu',
  myHighMenu: 'fepMyHighMenu',
  reeferMigration: 'fepReeferMigration',
  smartSortUpdates: 'dmGgSmartSortUpdates',
  specialWeights: 'ggSpecialWeights',

  /** Bargain Buds Flags */
  bbExcludeQualifiersFromProductDiscounts:
    'bbExcludeQualifiersFromProductDiscounts',

  /** Pineapple Express Flags */
  kioskOrderConfirmationRating: 'kioskOrderConfirmationRatingEnabled',

  /** Sour Chiesel Flags */
  bloomPlusBetaNav: 'bloomPlusBetaNavOption',
  scSmsIntegration: 'sourChieselStoreSpecificSmsIntegrationUi',
  scNotificationSettingsCardAccess: 'scNotificationSettingsCardAccess',
  scSpecialsBetaAccess: 'sourChieselSpecialsBetaAccess',
  scGlobalSpecialsBeta: 'sourChieselGlobalSpecialsBeta',
  scQuickActionBeta: 'scQuickActionBeta',
  scShowMenuRowIdInModal: 'scShowMenuRowIdInModal',
  scStackingSettingsPosStores: 'scStackingSettingsForPosStores',
  scAllProductsSearchFrontend: 'scAllProductsSearchFrontend',
  scBulkSpecialsExtraWeightsFrontend: 'scBulkSpecialsExtraWeightsFrontend',
  scBizAdminSunsettingFe: 'scBizAdminSunsettingFe',
  scMenuRowSchedulingFe: 'scMenuRowSchedulingFe',
  scSpendThresholdSpecialsFrontend: 'scSpendThresholdSpecialsFrontend',
  scSpecialsCustomBadge: 'scSpecialsCustomBadge',
  // Usually SC flags are in the Manager project, but this one is in Consumer since it's used in Legacy Biz Admin
  consumerBoostUseNewTable: 'boostUseNewTable',
  consumerUseGlobalSpecials: 'scUseGlobalSpecials',
  consumerBizAdminSunsetting: 'scBizAdminSunsetting',
  // This is named the same as the business flag but it's in the consumer project
  consumerSpecialsCustomBadge: 'scSpecialsCustomBadge',

  /** DM Flags */
  dmRecommendedSortVersion: 'dmRecommendedSortVersion',

  /** CRM Flag */
  bbCrmTracking: 'bbCrmTracking',
} as const;
